import * as tf from '@tensorflow/tfjs';
import { Camera } from '@mediapipe/camera_utils';
import { FaceMesh } from '@mediapipe/face_mesh';

export class InputCameraHandler {
    constructor(devType, videoRef, muscules = 0){

        // Right eye indices
        const RIGHT_EYE = [33, 7, 163, 144, 145, 153, 154, 155, 133, 173, 157, 158, 159, 160, 161, 246];
        // Left eye indices
        const LEFT_EYE = [362, 382, 381, 380, 374, 373, 390, 249, 263, 466, 388, 387, 386, 385, 384, 398];

        const CENTER_POINT = 152;

        this.common = [];
        let rat = 0;
        let BLINK_RATIO = 5.5;
        let ISTOR = 16
        let queueL = [];
        let queueR = [];
        let common = [];
        this.keys = [];

        // iOS setup for Safaris
        if (devType === 1) {
            BLINK_RATIO *= 3;
        }

        // Euclidean Distance
        function euclideanDistance(point, point1) {
            let x = point.x;
            let y = point.y;
            let x1 = point1.x;
            let y1 = point1.y;
            const distance = Math.sqrt((x1 - x) ** 2 + (y1 - y) ** 2);

            return distance;
        }

        // Blinking Ratio
        function blinkRatio(landmarks, rightIndices, leftIndices) {
            // Right Eye
            // Horizontal line
            const rhRight = landmarks[rightIndices[0]];
            const rhLeft = landmarks[rightIndices[8]];
            // Vertical line
            const rvTop = landmarks[rightIndices[12]];
            const rvBottom = landmarks[rightIndices[4]];

            // Left Eye
            // Horizontal line
            const lhRight = landmarks[leftIndices[0]];
            const lhLeft = landmarks[leftIndices[8]];
            // Vertical line
            const lvTop = landmarks[leftIndices[12]];
            const lvBottom = landmarks[leftIndices[4]];

            const rhDistance = euclideanDistance(rhRight, rhLeft);
            const rvDistance = euclideanDistance(rvTop, rvBottom);

            const lhDistance = euclideanDistance(lhRight, lhLeft);
            const lvDistance = euclideanDistance(lvTop, lvBottom);

            const reRatio = rhDistance / rvDistance;
            const leRatio = lhDistance / lvDistance;

            const ratio = (reRatio + leRatio) / 2;
            return ratio;
        }
        
        let model;
        async function loadModel() {
            model = undefined;
            model = await tf.loadLayersModel("/models/model.json");
        }

        loadModel();

        function indexOfMax(arr) {
            if (arr.length === 0) {
                return -1;
            }
          
            var max = arr[0];
            var maxIndex = 0;
          
            for (var i = 1; i < arr.length; i++) {
                if (arr[i] > max) {
                    maxIndex = i;
                    max = arr[i];
                }
            }
          
            return maxIndex;
        }

        function reco(track) {
            let input_xs = tf.tensor2d(track, [1,32])
            let output = model.predict(input_xs);
            const outputData = output.dataSync();
            // 0 is horizontal
            // 1 is vertical 
            // 2 is stop
            var argMaxVal = indexOfMax(outputData);
            // return recognized movement only at specific confidence otherwise stop
            if (outputData[argMaxVal] > 0.7)
                return argMaxVal;
            else
                return 2;
        }
        
        function movement(array)
        {
            if(array.length === 0)
                return null;
            var modeMap = {};
            var maxEl = array[0], maxCount = 1;
            for(var i = 0; i < array.length; i++)
            {
                var el = array[i];
                if(modeMap[el] == null)
                    modeMap[el] = 1;
                else
                    modeMap[el]++;  
                if(modeMap[el] > maxCount)
                {
                    maxEl = el;
                    maxCount = modeMap[el];
                }
            }
            return maxEl;
        }

        //function onResultsFaceMesh(results) {
        const onResultsFaceMesh = (results) => {
            let sumX = 0;
            let sumY = 0;
            let base_x;
            let base_y;
            //document.body.classList.add('loaded');

            if (results.multiFaceLandmarks) {
                for (const landmarks of results.multiFaceLandmarks) {
                    if (queueL.length === ISTOR*2) {
                        // start of movement trajectory
                        base_x = queueL[0][0];
                        base_y = queueL[0][1];
                        let preproc_queue = [];
                        let i;
                        // collect data for movement inference
                        for (i = 0; i < ISTOR * 2; i+=2) {
                            preproc_queue.push(queueL[i][0] - base_x)
                            preproc_queue.push(queueL[i][1] - base_y)
                        }
                        // first do inference for movement
                        let ret = reco(preproc_queue)
                        if (common.length === ISTOR) {
                            // third estimate most common movement
                            let val = movement(common);
                            switch(val) {
                                case 0:
                                    if (this.keys.includes('horizontal') !== true)
                                        this.keys.push('horizontal');
                                    break;
                                case 1:
                                    if (this.keys.includes('vertical') !== true)
                                        this.keys.push('vertical');
                                    break;
                                case 2:
                                default:
                                    this.keys.splice('horizontal', 1);
                                    this.keys.splice('vertical', 1);
                                    rat = blinkRatio(landmarks, RIGHT_EYE, LEFT_EYE);
                                    if (rat > BLINK_RATIO) {
                                        console.log(rat);
                                        console.log(BLINK_RATIO);
                                        if (this.keys.includes('blink') !== true)
                                            this.keys.push('blink');
                                    }
                                    break;
                            }
                            common.shift();
                        }
                        // second collect buffer of movement inference results
                        common.push(ret);
            
                        queueL.shift();
                        queueR.shift();
                    }
                    // collect raw data for every iris movement
                    // iris coordinate estimated as avarage of four eye landmarks
                    let n;

                    if (muscules === 0) {
                        for (n=474; n < 478; n++){
                            sumX += landmarks[n].x
                            sumY += landmarks[n].y
                        }

                        queueL.push([sumX/4, sumY/4]);
                    } else {
                        queueL.push([landmarks[CENTER_POINT].x, landmarks[CENTER_POINT].y]);
                    }

                    sumX = 0; sumY = 0;
                    for (n=469; n < 473; n++){
                        sumX += landmarks[n].x
                        sumY += landmarks[n].y
                    }
                    queueR.push([sumX/4, sumY/4]);
                }
            }
            //console.log(this.keys);
        }
        

        /*
        // Callback function to handle results from MediaPipe FaceMesh
        const onResultsFaceMesh = (results) => {
            if (results.multiFaceLandmarks) {
            for (const landmarks of results.multiFaceLandmarks) {
                const blinkThreshold = 5.5; // Set a blink ratio threshold for detection
                const ratio = blinkRatio(landmarks, RIGHT_EYE, LEFT_EYE);
    
                if (ratio > blinkThreshold) {
                    // If blink is detected and not already registered, add 'blink' to the keys
                    if (!this.keys.includes('blink')) {
                        this.keys.push('blink');
                        //onBlinkDetected('blink'); // Notify parent component about the blink detection
                    }
                } else {
                    // If no blink detected, remove 'blink' from the keys
                    //inputKeysRef.current = inputKeysRef.current.filter((key) => key !== 'blink');
                    this.keys.splice('blink', 1);
                    console.log("Need to debug");
                }
            }
            }
        };
        */

        // Initialize video element
        const videoElement = videoRef.current;
        videoElement.setAttribute('autoplay', '');
        videoElement.setAttribute('muted', '');
        videoElement.setAttribute('playsinline', '');

        /**
        * Solution options.
        */
        const faceMeshConfig = {
            selfieMode: true,
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        };
        videoElement.classList.toggle('selfie', faceMeshConfig.selfieMode);

        const faceMesh = new FaceMesh({locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
        }});

        faceMesh.setOptions(faceMeshConfig);
        faceMesh.onResults(onResultsFaceMesh);

        this.camera = new Camera(videoElement, {
        onFrame: async () => {
            await faceMesh.send({image: videoElement});
        },
            width: 1920,
            height: 1080
        });


    }

    start () {
        console.log("Start camera");
        this.camera.start();
    };
    stop () {
        console.log("Stop camera");
        this.camera.keys = [];
        this.camera.stop();
    };
}
