import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import Img from '../logo_mini.svg';

export default function SitemarkIcon() {
  return (
    <>
    <a href="/"><img src={Img} width={30} height={30} href="/"/></a>
    </>
  );
}
