import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('access_token'); // You can retrieve from cookie if stored there

  // If no token exists, redirect to the login page
  return token ? children : <Navigate to="/" />;
};

export default PrivateRoute;