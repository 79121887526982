import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/AppAppBar';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import Footer from '../components/Footer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function DialogConsent() {
  const [open, setOpen] = React.useState(true); // Initially, modal is open

  React.useEffect(() => {
    // Check if user has already agreed to the consent
    const consentGiven = localStorage.getItem('videoConsent');
    console.log(consentGiven);
    if (consentGiven === null)
      setOpen(false); // Show modal if consent hasn't been given
  }, [open]);

  const handleAgree = () => {
    // Store consent in localStorage
    localStorage.setItem('videoConsent', 'true');
    setOpen(false); // Close the modal
  };

  return (
    <>
    {open ? (
          <Dialog
          onClose={handleAgree}
          aria-labelledby="consent-dialog-title"
          aria-describedby="consent-dialog-description"
          >
          <DialogTitle id="consent-dialog-title">Video Processing Consent</DialogTitle>
          <DialogContent>
            <DialogContentText id="consent-dialog-description">
              This app processes video directly on your device and does not collect or store any private information.
              By continuing to use this app, you agree to this process.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAgree} color="primary" variant="contained">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
    ):( <></>

    )};
  </>);
}

export default function MarketingPage() {
  const [mode, setMode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });

  /*
  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);
  */

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
  };

  return (
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline enableColorScheme />
        <AppAppBar />
        <Features />
        <div>
          <Divider />
          <Pricing />
          <Divider />
          <Footer />
        </div>
      </ThemeProvider>
  );
}
